module.exports = {
  LOG_TYPES: {
    BROWSER: 'browser',
    MERCHANT: 'merchant',
    ERROR: 'error',
    PAYLOAD: 'payload',
    FORTER: 'forter',
  },
  CHALLENGE_POSITIONS: {
    AJAX_REDIRECT: { title: 'Ajax Redirect', value: 'AJAX_REDIRECT' },
    MODAL: { title: 'Modal', value: 'MODAL' },
    CHECKOUT: { title: 'Checkout', value: 'CHECKOUT' },
  },

  SCHEMA_VALIDATION_MODES: {
    ON: 'on',
    OFF: 'off',
  },

  FORTER_BACKENDS: {
    USE: { title: 'Forter USE', value: 'USE' },
    LOCAL: { title: 'Forter local', value: 'LOCAL' },
  },
  FORTER_TOKENIZATION_ENVS: {
    PROD: { title: 'Production (PCI)', value: 'production' },
    PRODEU: { title: 'Production EU (PCI)', value: 'production-eu' },
    SANDBOX: { title: 'Sandbox (Test cards)', value: 'sandbox' },
    LOCALHOST: { title: 'Localhost (Dev only)', value: 'localhost' },
  },
  CARD_NETWORKS_TO_USE: {
    DEFAULT: { title: 'Default', value: 'default' },
    CB: { title: 'Cartes Bancaires', value: 'CarteBancaire' },
  },
  EXECUTORS: {
    FORTER: 'FORTER',
    MERCHANT: 'MERCHANT',
  },
  NOTIFICATIONS: {
    TRIGGER_CHALLENGE: 'Sending data to trigger challenge...',
    INIT_3DS: 'Initialising 3DS...',
    SENDING_DATA_TO_ISSUER: 'Sending data to issuer...',
    TRIGGER_VERIFY: 'Verifying challenge',
  },
  TABS: {
    FLOWS: { title: 'Flows', icon: 'faAlignJustify' },
    ERROR_FLOWS: { title: 'Error Flows', icon: 'faAlignJustify' },
    THREE_DS: { title: '3DS Settings', icon: 'faCog' },
    HINTS: { title: 'Hints', icon: 'faLightbulb' },
  },
  FORTER_DECISIONS: {
    APPROVE: 'APPROVE',
    DECLINE: 'DECLINE',
    NOT_REVIEWED: 'NOT REVIEWED',
  },
  THREE_DS_VERSIONS: {
    THREE_DS_ONE: '1.0.2',
    THREE_DS_TWO: '2.1.0',
    THREE_DS_TWO_TWO: '2.2.0',
  },
  MIT_TYPES: {
    RECURRING_TRANSACTION: 'RECURRING_TRANSACTION',
    INSTALMENT_TRANSACTION: 'INSTALMENT_TRANSACTION',
    ADD_CARD: 'ADD_CARD',
    MAINTAIN_CARD_INFORMATION: 'MAINTAIN_CARD_INFORMATION',
    ACCOUNT_VERIFICATION: 'ACCOUNT_VERIFICATION',
    SPLIT_OR_DELAYED_SHIPMENT: 'SPLIT_OR_DELAYED_SHIPMENT',
    TOP_UP: 'TOP_UP',
    MAIL_ORDER: 'MAIL_ORDER',
    TELEPHONE_ORDER: 'TELEPHONE_ORDER',
    WHITELIST_STATUS_CHECK: 'WHITELIST_STATUS_CHECK',
    OTHER_PAYMENT: 'OTHER_PAYMENT',
  },
};
